
import { Component, Vue } from 'vue-property-decorator';
import ListFilter from '../components/list-filter.vue';
import moment from "moment";
import { InvalidMessages } from "@/mixins/validation-mixin";
import { BillingList } from '@/interface/interface';
import { INVOICE_STATUS, LIST_UPLOAD_STATUS, PROJECT_SORT } from '@/enum/enums';
import listUploadComponent from './list-upload.vue';

@Component({
  components: { ListFilter, listUploadComponent }
})
export default class ProjectBilling extends Vue {
  private page = 1;
  private totalPages = 0;
  private totalDocs = 0
  private limit = 10;
  private error = null;
  private filterOptions: Array<{ text: string, value: string }> = [{ text: 'Select', value: '' }, { text: 'Title', value: 'title' }, { text:'surveyNo', value: 'surveyNo' }];
  private filterType = '';
  private filterValue = '';
  private invoices: string[] = [];

  private sortOrder: PROJECT_SORT = PROJECT_SORT.DESC;
  private list: BillingList[] = [];

  async created(): Promise<void> {
    this.getQuery();
    await this.load();
  }

  private getQuery() {
    const { query } = this.$route;
    const { page, filterType, filterValue, startDate, endDate, sortOrder, surveyIds } = query;
    if (surveyIds) this.invoices = surveyIds as string[];
    if (page && !isNaN(+page)) this.page = +page;
    if (filterType && filterValue) {
      if (typeof filterType === 'string') {
        this.filterType = filterType;
      }
      if (typeof filterValue === 'string') this.filterValue = filterValue;
    }
    if (startDate || endDate) {
      if (typeof startDate === 'string') this.projectStartDate = startDate;
      if (typeof endDate === 'string') this.projectEndDate = endDate;
    }
    if (sortOrder) {
      if (typeof sortOrder === 'string') this.sortOrder = sortOrder as PROJECT_SORT;
    }
  }

  async load(): Promise<void> {
    this.loading = true;
    try {
      const params = await this.generateQuery();
      const { result, data, message } = await this.axios.get('/billing/list', { params });
      if (result) {
        const { docs, page, totalPages, totalDocs } = data;
        this.page = page;
        this.list = docs;
        this.totalPages = totalPages;
        this.totalDocs = totalDocs;
      } else {
        const [msg] = message as InvalidMessages[];
        this.errorMessage(msg.message.join('\r\n'));
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  private async generateQuery() {
    const {
      page,
      filterType,
      filterValue,
      sortOrder = PROJECT_SORT.DESC,
    } = this.$route.query;

    this.sortOrder = sortOrder as PROJECT_SORT;
    if (page) this.page = parseInt(page as string, 10);

    if (!this.projectStartDate && !this.projectEndDate) {
      const { data: { startDate, endDate } } = await this.axios.get(`project/default-date`);
      this.projectStartDate = startDate;
      this.projectEndDate = endDate;
    }

    const params: { [key: string]: string | number } = {
      page: this.page,
      limit: this.limit,
      sortOrder: sortOrder as string,
      startDate: this.projectStartDate,
      endDate: this.projectEndDate,
    }

    if (filterType && filterValue) {
      this.filterType = filterType as string;
      this.filterValue = filterValue as string;
      params[this.filterType] = this.filterValue;
    }
    return params;
  }

  private listUploadSuccess({ idx, surveyId, listUploadStatus }: { idx: number, surveyId: string, listUploadStatus: LIST_UPLOAD_STATUS }) {
    if (this.list[idx]) {
      this.list[idx].listUploadStatus = listUploadStatus;
      // this.list[idx].modal = false;
      this.load();
    }
  }

  private makeFilter(page = '1') {
    const query = { ...this.query, timeStamp: new Date().getTime().toString(), page };
    const convert = Object.entries(query).filter(([k, v]) => v).map(([k, v]) => {
      return `${k}=${v}`;
    }).join('&');
    return `?${convert}`;
  }

  private goFilter() {
    this.$router.push({ path: '/project/billing', query: { ...this.query, timeStamp: new Date().getTime().toString() } })
  }

  resetFilter() {
    this.$router.push({ path: '/project/billing', query: { timeStamp: new Date().getTime().toString() } })
  }

  get query() {
    return {
      filterType: this.filterType,
      filterValue: this.filterValue,
      startDate: this.projectStartDate,
      endDate: this.projectEndDate,
      sortOrder: this.sortOrder,
      page: this.page.toString(),
    };
  }
  linkGen (page: string) {
    return this.makeFilter(page);
  }

  dateFormat(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }
  get uploadStatusEnum() {
    return LIST_UPLOAD_STATUS;
  }
  get invoiceStatusEnum() {
    return INVOICE_STATUS;
  }
}
