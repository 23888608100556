
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BillingListUpload extends Vue {
  @Prop() private title!: string;
  @Prop() private id!: string;
  @Prop() private idx!: number;
  private listText: string | null = null;
  private saving = false;
  private messages: string[] = [];
  private done = false;

  async created() {
    await this.load();
  }

  private async load() {
    const url = `/billing/list-message`;
    const { message, result } = await this.axios({ url });
    if (message && result) {
      this.messages = this.validationMessages(message, true);
      console.log(this.messages);
    }
  }

  private async save() {
    this.saving = true;
    const url = `/billing/upload/${this.id}`;
    const { data, result, message } = await this.axios({ url, method: 'POST', data: { list: this.listText } });
    if (result) {
      const { surveyId, listUploadStatus } = data;
      this.$emit('save', { idx: this.idx, surveyId, listUploadStatus });
      this.done = true;
      this.validationMessages(message, true);
    }
    this.saving = false;
  }

  get notification(): string {
    return this.messages.join('\r\n');
  }

}
